import React, { useState, useEffect } from "react";
import { Card, Button, Pagination } from "antd";
import { motion } from "framer-motion";
import "tailwindcss/tailwind.css";
import { Link } from "react-router-dom";
import Img from "../../../assets/Kirkos Logo.png"; // Replace with the actual path to the logo
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS CSS

// Mock data for office information
const offices = [
    { id: 1, name: "Farmers and Urban Agriculture Development Office", logo: Img },
    { id: 2, name: "Environmental Protection Office", logo: Img },
    { id: 3, name: "Education Office", logo: Img },
    { id: 4, name: "Innovation and Technology Development Office", logo: Img },
    { id: 5, name: "Trade Office", logo: Img },
    { id: 6, name: "Executive Manager", logo: Img },
    { id: 7, name: "Employment and Skills Office", logo: Img },
    { id: 8, name: "Urban Beauty and Green Development Office", logo: Img },
    { id: 9, name: "Office of Justice", logo: Img },
    { id: 10, name: "Public Service Pool Management Finance Office", logo: Img },
    { id: 11, name: "Manager Pool Coordinator Office", logo: Img },
    { id: 12, name: "General Manager's Office", logo: Img },
    { id: 13, name: "Chief Executive Pool Administration and Finance Office", logo: Img },
    { id: 14, name: "Cleaning Management Office", logo: Img },
    { id: 15, name: "Food, Drug and Health Care Management and Control", logo: Img },
    { id: 16, name: "Design and Construction Management and Finance Office", logo: Img },
    { id: 17, name: "Planning and Development Commission Office", logo: Img },
    { id: 18, name: "Community Participation and Goodwill Coordination Office", logo: Img },
    { id: 19, name: "Government Property Management Office", logo: Img },
    { id: 20, name: "Health Office", logo: Img },
    { id: 21, name: "Cooperative Office", logo: Img },
    { id: 22, name: "Kirkos Sub City Housing Administration Office", logo: Img },
    { id: 23, name: "Administration Communication Office", logo: Img },
    { id: 24, name: "Public Service and Human Resources Development Office", logo: Img },
    { id: 25, name: "Women, Children and Social Affairs Office", logo: Img },
    { id: 26, name: "Culture, Arts and Tourism Office", logo: Img },
    { id: 27, name: "Peace and Security Office", logo: Img },
    { id: 28, name: "Building Permit and Inspection Office", logo: Img },
    { id: 29, name: "Council Office", logo: Img },
    { id: 30, name: "Industrial Development Office", logo: Img },
  ];

const OfficesList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30;

  // Calculate the indexes of the items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = offices.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  // Initialize AOS
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="min-h-screen bg-white text-[#0a4275] p-6">
      <h1 className="text-4xl font-extrabold text-center mb-12 text-[#f5a623]">
        Kirkos Subcity Administration Offices
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {currentItems.map((office, index) => (
          <motion.div
            key={index}
            className="w-full"
            data-aos="fade-up"
            data-aos-delay={`${index * 100}`}
          >
            <Card
              hoverable
              cover={
                <div className="flex items-center justify-center h-48">
                  <img
                    alt={office.name}
                    src={office.logo}
                    className="object-contain w-full h-full rounded-t-lg"
                  />
                </div>
              }
              className="bg-[#0a4275] text-white shadow-xl rounded-lg overflow-hidden transform transition-transform duration-500 hover:scale-105"
            >
              <Card.Meta
                title={
                  <h2 className="text-xl mx-auto text-center font-semibold text-white">
                    {office.name}
                  </h2>
                }
                description={
                  <div className="text-center mt-4">
                    <Link
                      to={`/management/offices/${office.id}`} // Ensure the path matches the route setup
                      className="text-center mt-4 text-yellow-500"
                    >
                      READ MORE
                    </Link>
                  </div>
                }
              />
            </Card>
          </motion.div>
        ))}
      </div>
      <div className="flex justify-center mt-8">
        <Pagination
          current={currentPage}
          total={offices.length}
          pageSize={itemsPerPage}
          onChange={onPageChange}
          showSizeChanger={false}
          className="text-center"
        />
      </div>
    </div>
  );
};

export default OfficesList;
