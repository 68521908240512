import React, { useState } from 'react';
import { Upload, Button, Input, Form, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import 'antd/dist/reset.css'; // Import Ant Design styles

const ComplaintForm = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('complaintName', values.name);
    formData.append('phoneNumber', values.phone);
    formData.append('institution', values.institution);
    formData.append('complaintDetail', values.detail);
    
    // Append each file in the fileList to formData
    fileList.forEach(file => {
      formData.append('evidence', file.originFileObj);
    });

    try {
      await axios.post('https://kirkossubcitymain.api.codenilesolutions.com/api/complaints', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Complaint submitted successfully!');
      form.resetFields();
      setFileList([]); // Clear fileList after successful submission
    } catch (error) {
      console.error('Error submitting complaint:', error);
      message.error('Failed to submit complaint. Please try again.');
    }
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const uploadProps = {
    beforeUpload: () => false, // Prevent automatic upload
    onChange: handleChange,
    fileList,
  };

  return (
    <section className="bg-blue-50 dark:bg-slate-800 py-16">
      <div className="max-w-4xl mx-auto px-4">
        <h2 className="text-2xl font-bold text-[#0a4275] text-center px-auto lg:text-4xl font-bold dark:text-white mb-8">Submit Your Complaint</h2>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          className="bg-white p-6 rounded-lg shadow-lg"
        >
          <Form.Item
            name="name"
            label="Compliant Name"
            rules={[{ required: true, message: 'Please enter your name' }]}
          >
            <Input placeholder="Enter your name" />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Compliant Phone Number"
            rules={[{ required: true, message: 'Please enter your phone number' }]}
          >
            <Input type="tel" placeholder="Enter your phone number" />
          </Form.Item>

          <Form.Item
            name="institution"
            label="Institution Where the Complaint Was Filed"
            rules={[{ required: true, message: 'Please enter the institution' }]}
          >
            <Input placeholder="Enter the institution name" />
          </Form.Item>

          <Form.Item
            name="detail"
            label="Complaint Detail"
            rules={[{ required: true, message: 'Please provide the details of your complaint' }]}
          >
            <Input.TextArea rows={4} placeholder="Provide details about your complaint" />
          </Form.Item>

          <Form.Item
            name="evidence"
            label="Evidence (Optional)"
          >
            <Upload {...uploadProps} multiple={true} listType="picture">
              <Button icon={<UploadOutlined />}>Upload Evidence</Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="w-full bg-blue-800 text-white">
              Submit Complaint
            </Button>
          </Form.Item>
        </Form>
      </div>
    </section>
  );
};

export default ComplaintForm;
