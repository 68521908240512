// import React from 'react';

// function ContactDesc() {
//   return (
//     <section className="bg-blue-50 dark:bg-slate-800" id="contact">
//       <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:py-20">
//         <div className="mb-4">
//           <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
//             <h2 className="font-heading mb-4 font-bold tracking-tight text-gray-900 dark:text-white text-3xl sm:text-4xl md:text-5xl">
//               Contact Us
//             </h2>
//             <p className="mx-auto mt-4 max-w-3xl text-lg sm:text-xl text-gray-600 dark:text-slate-400">
//               Kirkos Subcity Administration in Addis Ababa focuses on improving local governance, infrastructure, and community services, promoting economic growth, and enhancing residents' well-being.
//             </p>
//           </div>
//         </div>
//         <div className="flex flex-col gap-8 md:flex-row md:items-stretch">
//           <div className="flex-1 px-4 py-6 md:px-6 md:py-8">
//             <ul className="space-y-6">
//               <li className="flex items-start space-x-4">
//                 <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
//                   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
//                     <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
//                     <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
//                   </svg>
//                 </div>
//                 <div>
//                   <h3 className="mb-2 text-lg  font-medium leading-6 text-gray-900 dark:text-white">Our Address</h3>
//                   <p className="text-gray-600 dark:text-slate-400">Next to the Marriott Hotel, below Stephen's Church</p>
//                   <p className="text-gray-600 dark:text-slate-400">Addis Ababa, Ethiopia</p>
//                 </div>
//               </li>
//               <li className="flex items-start space-x-4">
//                 <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
//                   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
//                     <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
//                     <path d="M15 7a2 2 0 0 1 2 2"></path>
//                     <path d="M15 3a6 6 0 0 1 6 6"></path>
//                   </svg>
//                 </div>
//                 <div>
//                   <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900 dark:text-white">Contact</h3>
//                   <p className="text-gray-600 dark:text-slate-400">Email: wanasiraasfetsami@aakirkossubcity.com</p>
//                   <p className="text-gray-600 dark:text-slate-400">Phone: +251 115 50 69 25</p>
//                 </div>
//               </li>
//               <li className="flex items-start space-x-4">
//                 <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
//                   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
//                     <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
//                     <path d="M12 7v5l3 3"></path>
//                   </svg>
//                 </div>
//                 <div>
//                   <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900 dark:text-white">Working Hours</h3>
//                   <p className="text-gray-600 dark:text-slate-400">Monday - Friday: 08:00 - 17:00</p>
//                   <p className="text-gray-600 dark:text-slate-400">Saturday & Sunday: 08:00 - 12:00</p>
//                 </div>
//               </li>
//             </ul>
//           </div>
//           <div className="flex-1 px-4 py-6 md:px-6 md:py-8">
//             <div className="card h-full p-6 bg-white dark:bg-slate-900 rounded-lg shadow-md">
//               <h2 className="mb-4 text-2xl font-bold dark:text-white">Ready to Get Started?</h2>
//               <form id="contactForm">
//                 <div className="space-y-6">
//                   <div>
//                     <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Name</label>
//                     <input type="text" id="name" autoComplete="given-name" placeholder="Your name" className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 dark:bg-slate-800 dark:border-gray-600 dark:focus:border-blue-400 dark:focus:ring-blue-400" name="name" />
//                   </div>
//                   <div>
//                     <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Email</label>
//                     <input type="email" id="email" autoComplete="email" placeholder="Your email address" className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 dark:bg-slate-800 dark:border-gray-600 dark:focus:border-blue-400 dark:focus:ring-blue-400" name="email" />
//                   </div>
//                   <div>
//                     <label htmlFor="message" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Message</label>
//                     <textarea id="message" name="message" cols="30" rows="5" placeholder="Write your message..." className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 dark:bg-slate-800 dark:border-gray-600 dark:focus:border-blue-400 dark:focus:ring-blue-400"></textarea>
//                   </div>
//                 </div>
//                 <div className="text-center mt-6">
//                   <button type="submit" className="w-full bg-blue-800 text-white px-6 py-3 font-xl rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:bg-blue-700 dark:hover:bg-blue-600 dark:focus:ring-blue-500">Send Message</button>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default ContactDesc;
import React, { useState } from 'react';
import axios from 'axios';

function ContactDesc() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [formStatus, setFormStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!formData.name) {
      errors.name = 'Name is required';
      isValid = false;
    }
    if (!formData.email) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
      isValid = false;
    }
    if (!formData.message) {
      errors.message = 'Message is required';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      await axios.post('https://kirkossubcitymain.api.codenilesolutions.com/api/feedback', formData);
      setFormStatus('Message sent successfully!');
      setFormData({ name: '', email: '', message: '' }); // Clear form
    } catch (error) {
      console.error('Error sending feedback:', error);
      setFormStatus('Failed to send message. Please try again.');
    }
  };

  return (
    <section className="bg-blue-50 dark:bg-slate-800" id="contact">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:py-20">
        <div className="mb-4">
          <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
            <h2 className="font-heading mb-4 font-bold tracking-tight text-gray-900 dark:text-white text-3xl sm:text-4xl md:text-5xl">
              Contact Us
            </h2>
            <p className="mx-auto mt-4 max-w-3xl text-lg sm:text-xl text-gray-600 dark:text-slate-400">
              Kirkos Subcity Administration in Addis Ababa focuses on improving local governance, infrastructure, and community services, promoting economic growth, and enhancing residents' well-being.
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-8 md:flex-row md:items-stretch">
          <div className="flex-1 px-4 py-6 md:px-6 md:py-8">
            <ul className="space-y-6">
              <li className="flex items-start space-x-4">
                <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
                    <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                    <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
                  </svg>
                </div>
                <div>
                  <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900 dark:text-white">Our Address</h3>
                  <p className="text-gray-600 dark:text-slate-400">Next to the Marriott Hotel, below Stephen's Church</p>
                  <p className="text-gray-600 dark:text-slate-400">Addis Ababa, Ethiopia</p>
                </div>
              </li>
              <li className="flex items-start space-x-4">
                <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
                    <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
                    <path d="M15 7a2 2 0 0 1 2 2"></path>
                    <path d="M15 3a6 6 0 0 1 6 6"></path>
                  </svg>
                </div>
                <div>
                  <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900 dark:text-white">Contact</h3>
                  <p className="text-gray-600 dark:text-slate-400">Email: wanasiraasfetsami@aakirkossubcity.com</p>
                  <p className="text-gray-600 dark:text-slate-400">Phone: +251 115 50 69 25</p>
                </div>
              </li>
              <li className="flex items-start space-x-4">
                <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                    <path d="M12 7v5l3 3"></path>
                  </svg>
                </div>
                <div>
                  <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900 dark:text-white">Working Hours</h3>
                  <p className="text-gray-600 dark:text-slate-400">Monday - Friday: 08:00 - 17:00</p>
                  <p className="text-gray-600 dark:text-slate-400">Saturday & Sunday: 08:00 - 12:00</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="flex-1 px-4 py-6 md:px-6 md:py-8">
            <div className="card h-full p-6 bg-white dark:bg-slate-900 rounded-lg shadow-md">
              <h2 className="mb-4 text-2xl font-bold dark:text-white">Ready to Get Started?</h2>
              <form id="contactForm" onSubmit={handleSubmit}>
                <div className="space-y-6">
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Name</label>
                    <input
                      type="text"
                      id="name"
                      autoComplete="given-name"
                      placeholder="Your name"
                      className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 dark:bg-slate-800 dark:border-gray-600 dark:focus:border-blue-400 dark:focus:ring-blue-400"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {formErrors.name && <p className="text-red-500 text-sm">{formErrors.name}</p>}
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Email</label>
                    <input
                      type="email"
                      id="email"
                      autoComplete="email"
                      placeholder="Your email address"
                      className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 dark:bg-slate-800 dark:border-gray-600 dark:focus:border-blue-400 dark:focus:ring-blue-400"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {formErrors.email && <p className="text-red-500 text-sm">{formErrors.email}</p>}
                  </div>
                  <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Message</label>
                    <textarea
                      id="message"
                      name="message"
                      cols="30"
                      rows="5"
                      placeholder="Write your message..."
                      className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 dark:bg-slate-800 dark:border-gray-600 dark:focus:border-blue-400 dark:focus:ring-blue-400"
                      value={formData.message}
                      onChange={handleChange}
                    />
                    {formErrors.message && <p className="text-red-500 text-sm">{formErrors.message}</p>}
                  </div>
                </div>
                <div className="text-center mt-6">
                  <button
                    type="submit"
                    className="w-full bg-blue-800 text-white px-6 py-3 font-xl rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:bg-blue-700 dark:hover:bg-blue-600 dark:focus:ring-blue-500"
                  >
                    Send Message
                  </button>
                </div>
                {formStatus && (
                  <div className={`text-center mt-4 ${formStatus.includes('Failed') ? 'text-red-500' : 'text-green-500'}`}>
                    {formStatus}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactDesc;

