import React, { useState, useEffect } from "react";
import { Card, Button, Pagination } from "antd";
import { motion } from "framer-motion";
import "tailwindcss/tailwind.css";
import { Link } from "react-router-dom";
import Img from "../../assets/Kirkos Logo.png"; // Replace with the actual path to the logo
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS CSS

// Mock data for office information
const offices = [
  { name: "Farmers and Urban Agriculture Development Office", logo: Img },
  { name: "Environmental Protection Office", logo: Img },
  { name: "Education Office", logo: Img },
  { name: "Innovation and Technology Development Office", logo: Img },
  { name: "Trade Office", logo: Img },
  { name: "Executive Manager", logo: Img },
  { name: "Employment and Skills Office", logo: Img },
  { name: "Urban Beauty and Green Development Office", logo: Img },
  { name: "Office of Justice", logo: Img },
  { name: "Public Service Pool Management Finance Office", logo: Img },
  { name: "Manager Pool Coordinator Office", logo: Img },
  { name: "General Manager's Office", logo: Img },
  { name: "Chief Executive Pool Administration and Finance Office", logo: Img },
  { name: "Cleaning Management Office", logo: Img },
  { name: "Food, Drug and Health Care Management and Control", logo: Img },
  { name: "Design and Construction Management and Finance Office", logo: Img },
  { name: "Planning and Development Commission Office", logo: Img },
  { name: "Community Participation and Goodwill Coordination Office", logo: Img },
  { name: "Government Property Management Office", logo: Img },
  { name: "Health Office", logo: Img },
  { name: "Cooperative Office", logo: Img },
  { name: "Kirkos Sub City Housing Administration Office", logo: Img },
  { name: "Administration Communication Office", logo: Img },
  { name: "Public Service and Human Resources Development Office", logo: Img },
  { name: "Women, Children and Social Affairs Office", logo: Img },
  { name: "Culture, Arts and Tourism Office", logo: Img },
  { name: "Peace and Security Office", logo: Img },
  { name: "Building Permit and Inspection Office", logo: Img },
  { name: "Council Office", logo: Img },
  { name: "Industrial Development Office", logo: Img },
];

const OfficesList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  // Calculate the indexes of the items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = offices.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  // Initialize AOS
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-100 to-gray-200 p-6">
      <h1 className="text-4xl font-extrabold text-center mb-12 text-[#0a4275]">
        Kirkos Subcity Administration Offices
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
        {currentItems.map((office, index) => (
          <motion.div
            key={index}
            className="w-full"
            data-aos="fade-up"
            data-aos-delay={`${index * 100}`}
          >
            <Card
              hoverable
              cover={
                <div className="flex items-center justify-center h-48">
                  <img
                    alt={office.name}
                    src={office.logo}
                    className="object-contain w-full h-full rounded-t-lg"
                  />
                </div>
              }
              className="bg-white shadow-xl rounded-lg overflow-hidden transform transition-transform duration-500 hover:scale-105"
            >
              <Card.Meta
                title={<h2 className="text-xl mx-auto text-center font-semibold text-gray-800">{office.name}</h2>}
                description={
                  <div className="text-center mt-4">
                    <Link to="/management/offices">
                      <Button
                        type="primary"
                        className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition-all duration-300 ease-in-out"
                      >
                        READ MORE
                      </Button>
                    </Link>
                  </div>
                }
              />
            </Card>
          </motion.div>
        ))}
      </div>
      <div className="flex justify-center mt-8">
        <Pagination
          current={currentPage}
          total={offices.length}
          pageSize={itemsPerPage}
          onChange={onPageChange}
          showSizeChanger={false}
          className="text-center"
        />
      </div>
    </div>
  );
};

export default OfficesList;
