import React from 'react';
import { motion } from 'framer-motion';
import { FaImage } from 'react-icons/fa'; // Placeholder for logos

// Sample data
const offices = [
  { name: "Farmers and Urban Agriculture Development Office", logo: FaImage },
  { name: "Environmental Protection Office", logo: FaImage },
  { name: "Education Office", logo: FaImage },
  { name: "Innovation and Technology Development Office", logo: FaImage },
  { name: "Trade Office", logo: FaImage },
  { name: "Executive Manager", logo: FaImage },
  { name: "Employment and Skills Office", logo: FaImage },
  { name: "Urban Beauty and Green Development Office", logo: FaImage },
  { name: "Office of Justice", logo: FaImage },
  { name: "Public Service Pool Management Finance Office", logo: FaImage },
  { name: "Manager Pool Coordinator Office", logo: FaImage },
  { name: "General Manager's Office", logo: FaImage },
  { name: "Chief Executive Pool Administration and Finance Office", logo: FaImage },
  { name: "Cleaning Management Office", logo: FaImage },
  { name: "Food, Drug and Health Care Management and Control", logo: FaImage },
  { name: "Design and Construction Management and Finance Office", logo: FaImage },
  { name: "Planning and Development Commission Office", logo: FaImage },
  { name: "Community Participation and Goodwill Coordination Office", logo: FaImage },
  { name: "Government Property Management Office", logo: FaImage },
  { name: "Health Office", logo: FaImage },
  { name: "Cooperative Office", logo: FaImage },
  { name: "Kirkos Sub City Housing Administration Office", logo: FaImage },
  { name: "Administration Communication Office", logo: FaImage },
  { name: "Public Service and Human Resources Development Office", logo: FaImage },
  { name: "Women, Children and Social Affairs Office", logo: FaImage },
  { name: "Culture, Arts and Tourism Office", logo: FaImage },
  { name: "Peace and Security Office", logo: FaImage },
  { name: "Building Permit and Inspection Office", logo: FaImage },
  { name: "Council Office", logo: FaImage },
  { name: "Industrial Development Office", logo: FaImage },
];

const OrganizationStructure = () => {
  return (
    <div className="bg-white text-[#0a4275] py-16 px-4 lg:px-8">
      <div className="container mx-auto">
        <h1 className="text-4xl font-extrabold text-center mb-12 text-[#f5a623]]">Organizational Structure</h1>
        <div className="flex flex-col items-center">
          <motion.div
            className="flex flex-col items-center mb-8"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, type: "spring", stiffness: 120 }}
          >
            <h2 className="text-2xl font-bold mb-4 bg-[#0a4275] text-white p-4 rounded-lg">Chief Executive Manager</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {offices.map((office, index) => (
                <div key={index} className="flex flex-col items-center bg-[#0a4275] text-white p-4 rounded-lg shadow-lg">
                  <div className="w-24 h-24 flex items-center justify-center mb-4">
                    <office.logo className="text-white" size={48} />
                  </div>
                  <h3 className="text-xl font-semibold text-center px-auto">{office.name}</h3>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationStructure;
