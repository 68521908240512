import React from "react";
import { FaHome, FaComments, FaNewspaper, FaBullhorn, FaImages, FaFileAlt, FaBriefcase, FaCalendarAlt, FaEnvelope } from "react-icons/fa";

const AdminWelcomePage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-50 to-blue-100 flex items-center justify-center">
      <div className="max-w-4xl mx-auto px-8 py-12 bg-white shadow-2xl rounded-2xl">
        <h1 className="text-4xl font-bold text-blue-900 mb-8 text-center">
          Welcome to Kirkos Subcity Administration
        </h1>
        <div className="text-lg text-gray-800 mb-8">
          <p className="text-center mb-6">
            Manage your subcity administration's online presence efficiently with our intuitive admin panel. Here’s what you can do:
          </p>
          <ul className="list-disc ml-6 mt-4 space-y-4">
            <li className="flex items-start space-x-2">
              <FaHome className="text-blue-500 mt-1" />
              <span className="ml-2">
                <strong>Home Page:</strong> Manage homepage content for visitors.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <FaBullhorn className="text-blue-500 mt-1" />
              <span className="ml-2">
                <strong>Add Director Message:</strong> Highlight announcements from the subcity's director.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <FaNewspaper className="text-blue-500 mt-1" />
              <span className="ml-2">
                <strong>Add News:</strong> Keep visitors informed with news updates.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <FaImages className="text-blue-500 mt-1" />
              <span className="ml-2">
                <strong>Add Gallery:</strong> Showcase events and activities through images.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <FaFileAlt className="text-blue-500 mt-1" />
              <span className="ml-2">
                <strong>Add Tenders:</strong> Manage and display tenders for public view.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <FaBriefcase className="text-blue-500 mt-1" />
              <span className="ml-2">
                <strong>Add Vacancy:</strong> Post job vacancies and manage applications.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <FaCalendarAlt className="text-blue-500 mt-1" />
              <span className="ml-2">
                <strong>Add Event:</strong> Add and manage events for the subcity.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <FaComments className="text-blue-500 mt-1" />
              <span className="ml-2">
                <strong>Add Quick Message:</strong> Send quick messages for urgent communication.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <FaComments className="text-blue-500 mt-1" />
              <span className="ml-2">
                <strong>View Feedback:</strong> Review and respond to feedback submitted by residents.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <FaEnvelope className="text-blue-500 mt-1" />
              <span className="ml-2">
                <strong>View Complaints:</strong> Review and address complaints from the community.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminWelcomePage;
