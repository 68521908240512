import React from 'react'
import Description from "../components/About/Description"
import AboutUs from "../components/About/AboutUs"
import Header from "../components/About/Header"
function About() {
  return (
    <div>
      {/* <Description/> */}
      <Header/>
      <AboutUs/>
      <Description/>
    </div>
  )
}

export default About