import React, { useState } from "react";
import {
  FaHome,
  FaNewspaper,
  FaBullhorn,
  FaImages,
  FaUserTie,
  FaCommentDots,
  FaEnvelope,
  FaFileAlt,
  FaCalendarAlt,
  FaBriefcase,
  FaListAlt,
  FaSignOutAlt,
} from "react-icons/fa";
import AddNews from "./AddNews";
import AddDirectorMessage from "./AddDirectorMessage";
import ImageUpload from "./ImageUpload";
import LoginForm from "./LoginForm";
import AddTenders from "./AddTenders";
import AddVacancy from "./AddVacancy";
import AddEvent from "./AddEvent";
import ComplaintsList from "./ComplaintsList";
import ViewFeedback from "./ViewFeedback";
import AdminWelcomePage from "./AdminWelcomePage";
import QuickMessage from "./QuickMessage";
import AddCabinet from "./AddCabinet"

const NavbarA = () => {
  const [currentPage, setCurrentPage] = useState("home");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const navigateToPage = (page) => {
    setCurrentPage(page);
  };

  const handleLogin = (username, password) => {
    if (username === "Tsehay" && password === "123") {
      setIsAuthenticated(true);
    } else {
      alert("Invalid credentials");
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setCurrentPage("home");
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen md:h-auto overflow-y-auto">
      {!isAuthenticated ? (
        <LoginForm onLogin={handleLogin} />
      ) : (
        <>
          {/* Sidebar */}
          <aside className="bg-[#0a4275] text-gray-100 border-r shadow-md flex flex-col justify-between">
            <div>
              <div className="flex items-center h-16 border-b px-4">
                <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "home"
                      ? "text-yellow-500 bg-[#0a4275]"
                      : "text-white hover:bg-[#084c77]"
                  }`}
                  onClick={() => navigateToPage("home")}
                >
                  <FaHome className="mr-2" /> Home
                </a>
              </div>
              <nav className="flex flex-col mt-4 md:w-[250px]">
                <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "adddirector"
                      ? "text-yellow-500 bg-[#0a4275]"
                      : "text-white hover:bg-[#084c77]"
                  }`}
                  onClick={() => navigateToPage("adddirector")}
                >
                  <FaBullhorn className="mr-2" /> Add Director Message
                </a>
                <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "addnews"
                      ? "text-yellow-500 bg-[#0a4275]"
                      : "text-white hover:bg-[#084c77]"
                  }`}
                  onClick={() => navigateToPage("addnews")}
                >
                  <FaNewspaper className="mr-2" /> Add News
                </a>
                <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "addcabinet"
                      ? "text-yellow-500 bg-[#0a4275]"
                      : "text-white hover:bg-[#084c77]"
                  }`}
                  onClick={() => navigateToPage("addcabinet")}
                >
                  <FaNewspaper className="mr-2" /> Add Cabinet
                </a>
                <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "addgallery"
                      ? "text-yellow-500 bg-[#0a4275]"
                      : "text-white hover:bg-[#084c77]"
                  }`}
                  onClick={() => navigateToPage("addgallery")}
                >
                  <FaImages className="mr-2" /> Add Gallery
                </a>
                <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "addtenders"
                      ? "text-yellow-500 bg-[#0a4275]"
                      : "text-white hover:bg-[#084c77]"
                  }`}
                  onClick={() => navigateToPage("addtenders")}
                >
                  <FaFileAlt className="mr-2" /> Add Tenders
                </a>
                <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "addvacancy"
                      ? "text-yellow-500 bg-[#0a4275]"
                      : "text-white hover:bg-[#084c77]"
                  }`}
                  onClick={() => navigateToPage("addvacancy")}
                >
                  <FaBriefcase className="mr-2" /> Add Vacancy
                </a>
                <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "addevent"
                      ? "text-yellow-500 bg-[#0a4275]"
                      : "text-white hover:bg-[#084c77]"
                  }`}
                  onClick={() => navigateToPage("addevent")}
                >
                  <FaCalendarAlt className="mr-2" /> Add Event
                </a>
                <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "addquickmessage"
                      ? "text-yellow-500 bg-[#0a4275]"
                      : "text-white hover:bg-[#084c77]"
                  }`}
                  onClick={() => navigateToPage("addquickmessage")}
                >
                  <FaCalendarAlt className="mr-2" /> Add Quick Message
                </a>
                <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "viewfeedback"
                      ? "text-yellow-500 bg-[#0a4275]"
                      : "text-white hover:bg-[#084c77]"
                  }`}
                  onClick={() => navigateToPage("viewfeedback")}
                >
                  <FaCommentDots className="mr-2" /> View Feedback
                </a>
                <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "viewcomplaints"
                      ? "text-yellow-500 bg-[#0a4275]"
                      : "text-white hover:bg-[#084c77]"
                  }`}
                  onClick={() => navigateToPage("viewcomplaints")}
                >
                  <FaEnvelope className="mr-2" /> View Complaints
                </a>
              </nav>
            </div>
            <button
              onClick={handleLogout}
              className="text-white text-sm bg-red-600 px-4 py-2 rounded mt-4 mx-4 mb-20 hover:bg-red-700 self-center flex items-center"
            >
              <FaSignOutAlt className="mr-2" /> Logout
            </button>
          </aside>

          {/* Main Content */}
          <main className="flex-1 bg-gray-100">
            {currentPage === "home" && <AdminWelcomePage />}
            {currentPage === "adddirector" && <AddDirectorMessage />}
            {currentPage === "addnews" && <AddNews />}
            {currentPage === "addgallery" && <ImageUpload />}
            {currentPage === "addtenders" && <AddTenders />}
            {currentPage === "addvacancy" && <AddVacancy />}
            {currentPage === "addevent" && <AddEvent />}
            {currentPage === "viewfeedback" && <ViewFeedback />}
            {currentPage === "viewcomplaints" && <ComplaintsList />}
            {currentPage === "addquickmessage" && <QuickMessage />}
            {currentPage === "addcabinet" && <AddCabinet />}
          </main>
        </>
      )}
    </div>
  );
};

export default NavbarA;
