import React from "react";
import { useParams } from "react-router-dom";
import {
  FaPhone,
  FaFax,
  FaEnvelope,
  FaMapMarkedAlt,
  FaFacebook,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa";

// Sample data for demonstration purposes
const officeDescriptions = {
  1: {
    name: "Farmers and Urban Agriculture Development Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 1
Wareda 01 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 01 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 01 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12345
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  2: {
    name: "Environmental Protection Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 2
Wareda 02 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 02 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 02 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12346
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  3: {
    name: "Education Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 3
Wareda 03 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 03 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 03 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12347
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  4: {
    name: "Innovation and Technology Development Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 4
Wareda 04 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 04 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 04 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12348
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  5: {
    name: "Trade Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 5
Wareda 05 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 05 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 05 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12349
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  6: {
    name: "Executive Manager",
    description: `ABOUT KIRKOS SUBCITY
Woreda 6
Wareda 06 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 06 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 06 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12350
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  7: {
    name: "Employment and Skills Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 7
Wareda 07 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 07 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 07 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12351
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  8: {
    name: "Urban Beauty and Green Development Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 8
Wareda 08 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 08 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 08 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12352
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  9: {
    name: "Office of Justice",
    description: `ABOUT KIRKOS SUBCITY
Woreda 9
Wareda 09 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 09 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 09 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12353
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  10: {
    name: "Public Service Pool Management Finance Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 10
Wareda 10 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 10 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 10 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12354
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  11: {
    name: "Manager Pool Coordinator Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 11
Wareda 11 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 11 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 11 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12355
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  12: {
    name: "General Manager's Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 12
Wareda 12 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 12 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 12 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12356
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  13: {
    name: "Chief Executive Pool Administration and Finance Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 13
Wareda 13 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 13 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 13 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12357
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  14: {
    name: "Cleaning Management Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 14
Wareda 14 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 14 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 14 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12358
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  15: {
    name: "Food, Drug and Health Care Management and Control",
    description: `ABOUT KIRKOS SUBCITY
Woreda 15
Wareda 15 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 15 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 15 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12359
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  16: {
    name: "Design and Construction Management and Finance Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 16
Wareda 16 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 16 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 16 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12360
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  17: {
    name: "Planning and Development Commission Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 17
Wareda 17 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 17 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 17 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12361
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  18: {
    name: "Community Participation and Goodwill Coordination Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 18
Wareda 18 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 18 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 18 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12362
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  19: {
    name: "Government Property Management Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 19
Wareda 19 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 19 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 19 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12363
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  20: {
    name: "Health Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 20
Wareda 20 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 20 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 20 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12364
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  21: {
    name: "Cooperative Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 21
Wareda 21 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 21 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 21 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12365
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  22: {
    name: "Kirkos Sub City Housing Administration Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 22
Wareda 22 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 22 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 22 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12366
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  23: {
    name: "Administration Communication Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 23
Wareda 23 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 23 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 23 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12367
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  24: {
    name: "Public Service and Human Resources Development Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 24
Wareda 24 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 24 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 24 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12368
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  25: {
    name: "Women, Children and Social Affairs Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 25
Wareda 25 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 25 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 25 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12369
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  26: {
    name: "Culture, Arts and Tourism Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 26
Wareda 26 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 26 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 26 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12370
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  27: {
    name: "Peace and Security Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 27
Wareda 27 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 27 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 27 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12371
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  28: {
    name: "Building Permit and Inspection Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 28
Wareda 28 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 28 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 28 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12372
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  29: {
    name: "Council Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 29
Wareda 29 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 29 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 29 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 12373
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
  30: {
    name: "Industrial Development Office",
    description: `ABOUT KIRKOS SUBCITY
Woreda 30
Wareda 30 is an institution located in the Getu commercial center.

Vision
Institutions under the administration of Ward 30 and the community with competitive and motivated human resources through modern procedures and organization, 2017 to make the district one of the model districts in the city.

Mission
To create an organization and procedure based on the needs of the users of the society so that the institutions under the management of Ward 30 can provide efficient, effective and fair services to the society, to build up their capacity by providing feedback and continuous support by developing and increasing the use of information communication technology.

Core Value
 Good conscience and good heart
 Advanced use of technology
 Generate new innovations
 Love of work and dedication
 Accountability
 Transparency
 Working in cooperation
 Efficient and effective service

Phone: +251 115 50 69 25
Fax: 25111111112
Email: wanasiraasfetsami@aakirkossubcity.com
PO Box: 951
Address: Kirkos sub city next to Sanit Estifanos Church`,
    coordinates: { lat: 9.0108, lng: 38.7618 },
  },
};

const OfficeDetail = () => {
  const { id } = useParams();
  const office = officeDescriptions[id];

  if (!office) {
    return (
      <div className="bg-gray-100 text-gray-800 py-16 px-4 lg:px-8">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl font-extrabold text-red-600 mb-6">
            Office Details Not Found
          </h1>
          <p className="text-lg">
            We couldn't find the details for the requested office.
          </p>
        </div>
      </div>
    );
  }

  const { name, description, coordinates } = office;
  const contactInfo = description
    .split("\n")
    .filter(
      (line) =>
        line.includes("Phone") ||
        line.includes("Fax") ||
        line.includes("Email") ||
        line.includes("PO Box") ||
        line.includes("Address")
    );

  return (
    <div className="bg-gray-100 text-gray-800 py-16 px-4 lg:px-8">
      <div className="container mx-auto">
        <div className="bg-white p-8 rounded-lg shadow-lg mb-12">
          <h1 className="text-4xl font-extrabold text-blue-700 mb-6">{name}</h1>
          <p className="text-lg mb-6">{description}</p>
          <div>
            <h2 className="text-3xl font-semibold text-blue-600 mb-4">
              Contact Information
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {contactInfo.map((info, index) => (
                <div key={index} className="flex items-center space-x-3 mb-4">
                  {info.includes("Phone") && (
                    <FaPhone className="text-xl text-blue-600" />
                  )}
                  {info.includes("Fax") && (
                    <FaFax className="text-xl text-gray-600" />
                  )}
                  {info.includes("Email") && (
                    <FaEnvelope className="text-xl text-red-600" />
                  )}
                  {info.includes("PO Box") && (
                    <FaMapMarkedAlt className="text-xl text-green-600" />
                  )}
                  {info.includes("Address") && (
                    <FaMapMarkedAlt className="text-xl text-gray-700" />
                  )}
                  <p className="text-lg">{info}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-8 flex justify-center space-x-4">
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-3xl text-blue-600 hover:text-blue-800 transition-colors"
            >
              <FaFacebook />
            </a>
            <a
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-3xl text-blue-400 hover:text-blue-600 transition-colors"
            >
              <FaTwitter />
            </a>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-3xl text-pink-500 hover:text-pink-700 transition-colors"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
        <div className="w-full">
          <div className="text-4xl lg:text-5xl font-extrabold text-center text-blue-800 mb-8">
            <h1>Location</h1>
          </div>
          <div className="relative w-full h-96 sm:h-[450px] mb-8">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.585133794196!2d38.76353797490901!3d9.010266691050319!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b85bb716dde79%3A0x42b9998ef308d035!2sKirkos%20SubCity%20Administration!5e0!3m2!1sen!2set!4v1721467511477!5m2!1sen!2set"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Kirkos Sub City Administration"
              className="absolute inset-0 w-full h-full rounded-lg shadow-lg"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OfficeDetail;
