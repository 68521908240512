import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Card, Pagination, Input, Empty, Modal } from 'antd';

const { Search } = Input;

const ComingEvents = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get('https://kirkossubcitymain.api.codenilesolutions.com/api/events');
      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const handleSearch = (value) => {
    const filtered = data.filter(item =>
      item.title.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
    setCurrentPage(1); // Reset to the first page on search
  };

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };

  const showDetails = (event) => {
    setSelectedEvent(event);
  };

  const handleCloseModal = () => {
    setSelectedEvent(null);
  };

  return (
    <div className="bg-[#f5f5f5] py-16 px-4 lg:px-8" data-aos="fade-up">
      <div className="container mx-auto">
        <h1 className="text-3xl font-bold text-[#0a4275] mb-8 text-center">Upcoming Events</h1>

        <div className="mb-6 flex justify-center">
          <Search
            placeholder="Search by Event Title"
            onSearch={handleSearch}
            style={{ maxWidth: '400px', width: '100%' }}
            allowClear
            className="rounded-md"
          />
        </div>

        {filteredData.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredData.map(event => (
              <Card
                key={event._id}
                title={event.title}
                extra={<button onClick={() => showDetails(event)} className="text-blue-500 hover:text-blue-700">Details</button>}
                className="bg-white shadow-lg rounded-lg hover:shadow-xl transition-shadow"
                bordered={false}
                data-aos="fade-up"
              >
                <p className="text-gray-700 text-sm mb-2"><strong>Date:</strong> {new Date(event.eventDate).toLocaleDateString()}</p>
                <p className="text-gray-700 text-sm mb-2"><strong>Location:</strong> {event.location}</p>
              </Card>
            ))}
          </div>
        ) : (
          <Empty description="No events available" />
        )}

        {filteredData.length > 0 && (
          <div className="flex justify-center mt-8">
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={filteredData.length}
              onChange={handlePaginationChange}
              showSizeChanger={false}
              className="ant-pagination"
            />
          </div>
        )}

        {selectedEvent && (
          <Modal
            title={selectedEvent.title}
            visible={true}
            onCancel={handleCloseModal}
            footer={null}
            className="text-[#0a4275]"
          >
            <p><strong>Date:</strong> {new Date(selectedEvent.eventDate).toLocaleDateString()}</p>
            <p><strong>Location:</strong> {selectedEvent.location}</p>
            <p><strong>Details:</strong> {selectedEvent.description}</p>
            {selectedEvent.image && (
              <div>
                <strong>Image:</strong>
                <img src={`https://kirkossubcitymain.api.codenilesolutions.com/${selectedEvent.image}`} alt={selectedEvent.title} style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }} />
              </div>
            )}
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ComingEvents;
