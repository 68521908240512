import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

// Sample data
const woredas = [
  { id: 11, name: "Woreda 11" },
  { id: 1, name: "Woreda 1" },
  { id: 2, name: "Woreda 02" },
  { id: 3, name: "Woreda 03" },
  { id: 4, name: "Woreda 04" },
  { id: 5, name: "Woreda 05" },
  { id: 7, name: "Woreda 07" },
  { id: 8, name: "Woreda 08" },
  { id: 9, name: "Woreda 09" },
  { id: 10, name: "Woreda 10" },
];

const Woredas = () => {
  return (
    <div className="bg-white text-[#0a4275] py-16 px-4 lg:px-8">
      <div className="container mx-auto">
        <h1 className="text-3xl font-bold text-center mb-6 text-[#f5a623]">
          Kirkos Sub City Woredas
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {woredas.map((woreda) => (
            <motion.div
              key={woreda.id}
              className="bg-[#0a4275] text-white p-6 rounded-lg shadow-lg"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="text-xl font-bold mb-4">{woreda.name}</h2>
              <Link
                to={`/management/woredas/${woreda.id}`} // Ensure the path matches the route setup
                className="bg-[#f5a623] text-white py-2 px-4 rounded hover:bg-[#e68a1f]"
              >
                READ MORE
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Woredas;
