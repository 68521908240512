import React from 'react'
import NavbarA from '../components/Admin/NavbarA'

function Admin() {
  return (
    <div>
        <NavbarA/>
    </div>
  )
}

export default Admin