import React from 'react';
import { useParams } from 'react-router-dom';

// Sample data for demonstration purposes
const woredaDescriptions = {
  11: "Details about Woreda 11...",
  1: "Details about Woreda 1...",
  2: "Details about Woreda 02...",
  3: "Details about Woreda 03...",
  4: "Details about Woreda 04...",
  5: "Details about Woreda 05...",
  7: "Details about Woreda 07...",
  8: "Details about Woreda 08...",
  9: "Details about Woreda 09...",
  10: "Details about Woreda 10...",
};

const WoredaDetail = () => {
  const { id } = useParams();
  const description = woredaDescriptions[id];

  return (
    <div className="bg-white text-[#0a4275] py-16 px-4 lg:px-8">
      <div className="container mx-auto">
        <h1 className="text-3xl font-bold text-center mb-6 text-[#f5a623]">Woreda {id}</h1>
        <p className="text-lg">{description}</p>
      </div>
    </div>
  );
};

export default WoredaDetail;
