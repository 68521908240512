import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card } from "antd";
import { motion } from "framer-motion";
import "tailwindcss/tailwind.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS CSS
import Loader from '../../components/Loader'; // Import the Loader component

const DirectorMessageDisplay = () => {
  const [directorMessages, setDirectorMessages] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    // Initialize AOS
    AOS.init({ duration: 2000 });

    const fetchDirectorMessages = async () => {
      try {
        const response = await axios.get("https://kirkossubcitymain.api.codenilesolutions.com/api/director");
        setDirectorMessages(response.data);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching director messages:", error);
        setLoading(false); // Ensure loading is set to false even on error
      }
    };

    fetchDirectorMessages();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-[#0a4275]">
        <Loader /> {/* Show loader while data is being fetched */}
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#0a4275] p-5">
      {directorMessages.map((message) => (
        <Card
          key={message._id}
          className="w-full max-w-8xl mx-auto shadow-lg rounded-lg overflow-hidden bg-white mb-8"
        >
          <div className="flex flex-col md:flex-row items-stretch">
            {/* Text Section */}
            <motion.div
              className="flex-1 p-5 md:p-10 flex flex-col justify-between"
              data-aos="fade-right"
            >
              <div>
                <h1 className="text-3xl md:text-4xl font-bold text-[#0a4275] mb-4">
                  {message.title}
                </h1>
                <p className="text-base md:text-lg text-gray-600 mb-4">
                  {message.description}
                </p>
              </div>
              <div className="mt-4 text-lg font-semibold text-gray-800">
                {message.ceoName}
                <br />
                {message.ceoPosition}
              </div>
            </motion.div>
            

            {/* Image Section */}
            <motion.div
              className="w-full md:w-1/2 p-5 flex justify-center items-center"
              data-aos="fade-left"
            >
              <div className="relative w-full h-full">
                <img
                  src={`https://kirkossubcitymain.api.codenilesolutions.com/${message.image}`}
                  alt={message.title}
                  className="w-full h-full rounded-lg shadow-md object-cover"
                />
                <div className="absolute inset-0 border-t-4 border-blue-500 animate-pulse"></div>
              </div>
            </motion.div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default DirectorMessageDisplay;
