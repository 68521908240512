import React from "react";
import Carousel from "../components/Home/Carousel";
import Counter from "../components/Home/Counter";
import DirectorMessage from "../components/Home/DirectorMessage";
import Offices from "../components/Home/Offices";
// import News from "../components/Home/News"
import News from "../components/News/News";
import Contact from "../components/Contact/ContactDesc";
import Banner from "../components/Home/Banner";
function Home() {
  return (
    <div>
      <Carousel />
      <Counter />
      <DirectorMessage />
      <Offices />
      <div className="bg-blue-500 p-4">
        <News />
      </div>
      <div >
        <Contact />
      </div>
      <Banner />
    </div>
  );
}

export default Home;
