import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Card, Button, Pagination, Spin, Alert } from "antd";
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import "tailwindcss/tailwind.css";
import axios from "axios";

const NewsDetail = () => {
  const { id } = useParams();
  const [newsItem, setNewsItem] = useState(null);
  const [recentNews, setRecentNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 3; // Number of recent news items per page

  useEffect(() => {
    AOS.init({ duration: 1000 });

    const fetchNewsItem = async () => {
      try {
        const response = await axios.get(`https://kirkossubcitymain.api.codenilesolutions.com/api/news/${id}`);
        setNewsItem(response.data);
        console.log("Here ",response.data)
      } catch (error) {
        console.error('Error fetching news item:', error);
        setError('Failed to fetch news item.');
      }
    };

    const fetchRecentNews = async () => {
      try {
        const response = await axios.get('https://kirkossubcitymain.api.codenilesolutions.com/api/news');
        setRecentNews(response.data.filter((item) => item._id !== id));
      } catch (error) {
        console.error('Error fetching recent news:', error);
        setError('Failed to fetch recent news.');
      }
    };

    const fetchData = async () => {
      await Promise.all([fetchNewsItem(), fetchRecentNews()]);
      setLoading(false);
    };

    fetchData();
  }, [id]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentRecentNews = recentNews.slice(indexOfFirstItem, indexOfLastItem);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  if (loading) {
    return <div className="min-h-screen flex items-center justify-center"><Spin size="large" /></div>;
  }

  if (error) {
    return <div className="min-h-screen flex items-center justify-center"><Alert message={error} type="error" /></div>;
  }

  if (!newsItem) {
    return <div className="min-h-screen flex items-center justify-center">No news item found</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-4xl mx-auto">
        {/* News Detail Card */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <Card
            cover={
              <img
                alt={newsItem.title}
                src={`https://kirkossubcitymain.api.codenilesolutions.com/${newsItem.image}`}
                className="w-full h-80 object-cover rounded-t-lg"
                style={{ objectFit: 'cover' }}
              />
            }
            className="shadow-lg rounded-lg bg-white"
            style={{ borderRadius: "10px", overflow: "hidden" }}
          >
            <Card.Meta
              title={<h1 className="text-3xl font-bold text-gray-800">{newsItem.title}</h1>}
              description={
                <div>
                  <p className="text-gray-600 mb-4">{newsItem.description}</p>
                  <p className="text-sm text-gray-500">{new Date(newsItem.date).toLocaleDateString('en-GB')}</p>
                  <Link to="/news">
                    <Button
                      type="primary"
                      className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Back to News
                    </Button>
                  </Link>
                </div>
              }
            />
          </Card>
        </motion.div>

        {/* Recent News Section */}
        <div className="mt-12">
          <h2
            className="text-3xl font-bold text-[#0a4275] mb-8"
            data-aos="fade-up"
          >
            Recent News
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {currentRecentNews.map((item, index) => (
              <motion.div
                key={item._id}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                data-aos="fade-up"
              >
                <Card
                  cover={
                    <img
                      alt={item.title}
                      src={`https://kirkossubcitymain.api.codenilesolutions.com/${item.image}`}
                      className="w-full h-32 object-cover rounded-t-lg"
                      style={{ objectFit: 'cover' }}
                    />
                  }
                  className="shadow-lg rounded-lg bg-white"
                  style={{ borderRadius: "10px", overflow: "hidden" }}
                >
                  <Card.Meta
                    title={<h4 className="text-lg font-semibold text-gray-800">{item.title}</h4>}
                    description={
                      <div>
                        <p className="text-gray-600 mb-2">
                          {item.description.length > 100
                            ? `${item.description.substring(0, 100)}...`
                            : item.description}
                        </p>
                        <p className="text-sm text-gray-500">{new Date(item.date).toLocaleDateString('en-GB')}</p>
                        <Button
                          type="link"
                          className="text-blue-600 hover:text-blue-800"
                        >
                          <Link to={`/news/${item._id}`}>Read More</Link>
                        </Button>
                      </div>
                    }
                  />
                </Card>
              </motion.div>
            ))}
          </div>
          <div className="flex justify-center mt-8">
            <Pagination
              current={currentPage}
              total={recentNews.length}
              pageSize={itemsPerPage}
              onChange={onPageChange}
              showSizeChanger={false}
              className="text-center"
              itemRender={(current, type, originalElement) => {
                if (type === 'prev') {
                  return <a className="ant-pagination-prev" aria-label="Previous page" style={{ color: '#0a4275' }}>{'<'}</a>;
                }
                if (type === 'next') {
                  return <a className="ant-pagination-next" aria-label="Next page" style={{ color: '#0a4275' }}>{'>'}</a>;
                }
                return originalElement;
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetail;
