import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import News from "./pages/News";
import Gallery from "./pages/Gallery";
import Management from "./pages/Management";
import ChiefExecutive from "./pages/ManDropdown/ChiefExecutive";
import Cabinets from "./pages/ManDropdown/Cabinets";
import Structure from "./pages/ManDropdown/Structure";
import Woredas from "./pages/ManDropdown/Woredas";
import WoredaDetail from "./components/ManagementDD/Woredas/WoredasDetail"; // Import the WoredaDetail component
import OfficeDetail from "./components/ManagementDD/Offices/OfficeDetail"; // Import the Office
import Offices from "./pages/ManDropdown/Offices";
import Announcement from "./pages/Announcement";
import Tender from "./pages/AnnDropdown/Tender";
import Vacancy from "./pages/AnnDropdown/Vacancy";
import Event from "./pages/AnnDropdown/Event";
import MainLayout from "./pages/MainLayout";
import ErrorPage from "./pages/ErrorPage";
import Contact from "./pages/Contact";
import Compliant from "./pages/Compliant";
import Admin from "./pages/Admin";

import NewsDetail from "./components/News/NewsDesc"
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "about",
          element: <About />,
        },
        {
          path: "news",
          element: <News />,
        },
        {
          path: "news/:id", // Route for news detail
          element: <NewsDetail />,
        },
        {
          path: "gallery",
          element: <Gallery />,
        },
        {
          path: "management",
          element: <Management />,
        },
        {
          path: "management/chief-executive",
          element: <ChiefExecutive />,
        },
        {
          path: "management/cabinets",
          element: <Cabinets />,
        },
        {
          path: "management/structure",
          element: <Structure />,
        },
        {
          path: "management/woredas",
          element: <Woredas />,
        },
        {
          path: "management/woredas/:id", // Route for Woreda detail pages
          element: <WoredaDetail />,
        },
        {
          path: "management/offices",
          element: <Offices />,
        },
        {
          path: "management/offices/:id", // Route for office detail
          element: <OfficeDetail />,
        },
        {
          path: "announcement",
          element: <Announcement />,
        },
        {
          path: "announcement/tender",
          element: <Tender />,
        },
        {
          path: "announcement/vacancy",
          element: <Vacancy />,
        },
        {
          path: "announcement/event",
          element: <Event />,
        },
        {
          path: "contact",
          element: <Contact />,
        },
        {
          path: "compliant",
          element: <Compliant />,
        },
        {
          path: "admin",
          element: <Admin/>,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
