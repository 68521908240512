import React from "react";
import { Carousel } from "antd";
// import 'antd/dist/antd.css'; // Import Ant Design CSS
import Image1 from "../../assets/Kirkos Logo.png";
import Image2 from "../../assets/Carousel/KirkosHead.png"
import Image3 from "../../assets/Carousel/Homeim1.jpg"
import Image4 from "../../assets/Carousel/Homeim2.jpg"

import Image6 from "../../assets/Carousel/Mubarek.jpg"

import Logo from "../../assets/Kirkos Logo.png";
import img1 from "../../assets/Carousel/Head.png"
import img2 from "../../assets/Carousel/BureauHead1.png"
import img3 from "../../assets/Carousel/BureauHead2.png"
import img4 from "../../assets/Carousel/Head3.png"
import img5 from "../../assets/Carousel/Head4.png"
import img6 from "../../assets/Carousel/Head5.png"
const App = () => (
  <Carousel autoplay>
    <div className="flex items-center justify-center h-[55vh] overflow-hidden bg-[#0a4275]">
      <img src={Logo} alt="Kirkos Logo" className="w-full h-full object-contain" />
    </div>
    <div className="flex items-center justify-center h-[55vh] overflow-hidden">
      <img src={img1} alt="Kirkos Logo" className="w-full h-full object-fill" />
    </div>
    <div className="flex items-center justify-center h-[55vh] overflow-hidden">
      <img src={img2} alt="Genet Dugassa" className="w-full h-full object-fill" />
    </div>
    <div className="flex items-center justify-center h-[55vh] overflow-hidden">
      <img src={img3} alt="Kirkos Logo" className="w-full h-full object-fill" />
    </div>
    <div className="flex items-center justify-center h-[55vh] overflow-hidden">
      <img src={img4} alt="Kirkos Logo" className="w-full h-full object-fill" />
    </div>
    <div className="flex items-center justify-center h-[55vh] overflow-hidden">
      <img src={img5} alt="Kirkos Logo" className="w-full h-full object-fill" />
    </div>
    <div className="flex items-center justify-center h-[55vh] overflow-hidden">
      <img src={img6} alt="Kirkos Logo" className="w-full h-full object-fill" />
    </div>
  </Carousel>
);

export default App;
